import React, { FunctionComponent } from 'react';

import { CookiePolicyPageStyle } from './CookiePolicyPageStyle';
import Layout from '../../layouts/Layout';
import { Helmet } from 'react-helmet';

const CookiePolicyPage: FunctionComponent<{}> = () => (
  <Layout isFAQPage>
    <CookiePolicyPageStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cookie Policy</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="content">
          <div className="title">Cookie Policy</div>
          <div className="note">
            <p>
              We use cookies, pixels and tags (which we shall collectively define as &ldquo;Cookies&rdquo;) on
              our website for a variety of purposes which are described in this Cookie Policy. By using our website you consent
              to the storing and accessing of Cookies on your device in accordance with the terms of this Cookie Policy.
            </p>
          </div>

          <div className="subtitle">What are Cookies?</div>
          <div className="note">
            <p>
              Cookies are small text files which are downloaded to your computer or mobile device when you visit
              a website or application. Your web browser (such as Internet Explorer, Mozilla Firefox, Safari or Google Chrome)
              then sends these Cookies back to the website or application on each subsequent visit so that they can recognise
              you and remember things like personalised details or user preferences.
          </p>
            <p>
              Cookies are very useful and do lots of different jobs which help to make your experience on
              websites as smooth as possible. For example, they let you move between web pages efficiently, remembering your
              preferences, and generally improving your experience (see below for more examples).
          </p>
            <p>
              They are referred to as <span>session</span>&nbsp;or&nbsp;<span>persistent</span>&nbsp;Cookies, depending on how long they are used:
          <ol>
                <li>
                  Session Cookies are temporary cookie files and only last for your online session and disappear
                  from your computer or device when you close your browser.
              </li>
                <li>
                  Persistent Cookies stay on your computer or device after the browser has been closed and last for
                  the period of time specified in the cookie. These persistent cookies are activated each time you visit the site
                  where the cookie was generated.
              </li>
              </ol>
              <p>
                There are also <span>first-party</span>&nbsp;and&nbsp;<span>third-party</span>&nbsp;Cookies. First-party Cookies are owned by the website a person is currently viewing, while
              third-party Cookies belong to a website other than the one a person is currently viewing. Compared to third-party
              Cookies, first-party Cookies are more widely accepted by browsers and stored for longer periods of time.
            </p>
            </p>
          </div>

          <div className="subtitle">How does TravelPix use Cookies?</div>
          <div className="subtitle">Session Cookies</div>
          <div className="note">
            <p>
              We use Cookies to uniquely identify a user&rsquo;s browsing session on our website and to allow us
              to coordinate this information with data from our website server.
            </p>
          </div>
          <div className="subtitle">Persistent Cookies</div>
          <div className="note">
            <p>
              We use Cookies to remember the language selected by the user when they come back to visit the
              website again. Polylang is the provider we use to allow for multiple languages on our website. To find out more
              about the Cookies they use, please click the following link &ndash;&nbsp;
            </p>
            <a target="_blank" href="https://www.google.com/url?q=https://polylang.pro/doc/is-polylang-compatible-with-the-eu-cookie-law&amp;sa=D&amp;ust=1607935318001000&amp;usg=AOvVaw1NTAIGoS265tLxVs_DEgeW">https://polylang.pro/doc/is-polylang-compatible-with-the-eu-cookie-la</a>
          </div>
          <div className="subtitle">Analytics</div>
          <div className="note">
            <p>
              We use Google&rsquo;s &ldquo;analytics&rdquo; Cookies which allow us to identify unique, but anonymous users.
              These Cookies can also calculate the number of people visiting our website, the date and time of a user&rsquo;s
              visit, the pages a user has viewed and the time spent by users on our website. This helps us gather feedback so
              that we can improve our website and better serve our users. To find out more about the Cookies Google Analytics
              use, please click the following link &ndash;&nbsp;
            </p>
            <a target="_blank" href="https://www.google.com/url?q=https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage&amp;sa=D&amp;ust=1607935318002000&amp;usg=AOvVaw2yIIrAwyiy72IhatowjIJq">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>
            <p>
              We use Google Tag Manager to manage and deploy tags on our website without having to modify the code. This
              allows us to share information from our website to Google Analytics. To find out more about Google Tag Manager,
              please click the following link &ndash;&nbsp;
            </p>
            <a target="_blank" href="https://www.google.com/url?q=https://marketingplatform.google.com/intl/en_uk/about/tag-manager/&amp;sa=D&amp;ust=1607935318003000&amp;usg=AOvVaw1amwaZzG58AT_qjh62UML3">https://marketingplatform.google.com/intl/en_uk/about/tag-manager/</a>
            <p>
              We use the Facebook Pixel, an analytics tool that allows us to measure the effectiveness of our advertising
              off of our website by understanding the actions people take on our website. When a user visits our website and
              takes an action (like completing a purchase or clicking a button), the Facebook pixel is triggered and reports
              this action. To find out more about how the Facebook pixel works, please click the following link &ndash;
            </p>
            <a target="_blank" href="https://www.google.com/url?q=https://www.facebook.com/business/help/471978536642445&amp;sa=D&amp;ust=1607935318004000&amp;usg=AOvVaw0ocytSc69Jng2kjo9zBynl">https://www.facebook.com/business/help/471978536642445</a>
          </div>

          <div className="subtitle">Managing Cookies in your browser</div>
          <div className="note">
            <p>
              Most modern browsers will allow you to:
            </p>
            <ol>
              <li>
                See what Cookies you&rsquo;ve got and delete them on an individual basis.
              </li>
              <li>
                Block third party Cookies.
              </li>
              <li>
                Block all Cookies from being set.
              </li>
              <li>
                Delete all Cookies when you close your browser.
              </li>
            </ol>
            <p>
              You should be aware that any preferences will be lost if you delete all Cookies. Ironically, this
              includes where you have opted out from Cookies, as this requires an opt-out cookie to be set. This means that if
              you opt out from Cookies and then delete all Cookies, your opt out will not be saved and you will need to opt out
              again (which will store a strictly necessary cookie so that your device can remember that you have opted
              out).
            </p>
            <p>
              If you block Cookies completely, many websites will not work properly and some functionality on
              these websites will not work at all. We do not recommend turning Cookies off for these reasons.
            </p>
            <p>
              The links below take you to the &lsquo;Help&rsquo; sections for each of the major browsers so that
              you can find out more about how to manage your Cookies.
            </p>
            <p>
              <span>Google Chrome: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=http://support.google.com/chrome/bin/answer.py?hl%3Den%26answer%3D95647&amp;sa=D&amp;ust=1607935318005000&amp;usg=AOvVaw2yWvkRgXB-uOOCB2dikrmw">http://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647</a>
            </p>
            <p>
              <span>Firefox: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=http://support.mozilla.org/en-US/kb/Cookies&amp;sa=D&amp;ust=1607935318006000&amp;usg=AOvVaw1ddPlFeumuAIizjFyIxZr8">http://support.mozilla.org/en-US/kb/Cookies</a>
            </p>
            <p>
              <span>Safari: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;ust=1607935318007000&amp;usg=AOvVaw3poMzPeMGc97_8A586135t">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
            </p>
            <p>
              <span>Safari iOS: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&amp;sa=D&amp;ust=1607935318007000&amp;usg=AOvVaw0-6fjdeglNscMN27LcuOPC">https://support.apple.com/en-us/HT201265</a>
            </p>
            <p>
              <span>Android: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=http://support.google.com/chrome/answer/2392971?hl%3Den-GB&amp;sa=D&amp;ust=1607935318008000&amp;usg=AOvVaw1ocmgc0SO2wMjj8HMl0NZw">http://support.google.com/chrome/answer/2392971?hl=en-GB</a>
            </p>
            <p>
              <span>Internet Explorer: &nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;ust=1607935318009000&amp;usg=AOvVaw06Q8URr0noR73OO7U7P03H">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
            </p>
          </div>

          <div className="subtitle">Managing analytics Cookies</div>
          <div className="note">
            <p>
              It is possible to opt out of having your browsing activity recorded by analytics Cookies. You can
              opt out of their Cookies by clicking on the following links. Please note that this will take you to the relevant
              third party&rsquo;s website and generate a &lsquo;no thanks&rsquo; cookie, which will stop any further cookies
              being set by those third parties.
            </p>
            <p>
              Don&rsquo;t forget that by not allowing analytics Cookies, this stops us from being able to learn
              what people like or don&rsquo;t like about TravelPix, so that we can make them better.
            </p>
            <p>
              <span>Google Analytics:&nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1607935318010000&amp;usg=AOvVaw0a2eCCvx_ttsxy-ffIZ6-N">http://tools.google.com/dlpage/gaoptout</a>
            </p>
            <p>
              <span>Facebook Analytics:&nbsp;</span>
              <a target="_blank" href="https://www.google.com/url?q=https://www.facebook.com/policies/cookies/&amp;sa=D&amp;ust=1607935318010000&amp;usg=AOvVaw1KX9-_h9cvIYr9-6r9PuXa">https://www.facebook.com/policies/cookies/</a>
            </p>
          </div>
        </div>
      </div >
    </CookiePolicyPageStyle >
  </Layout >
);

export default CookiePolicyPage;
