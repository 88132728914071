import styled from '@emotion/styled';

const CookiePolicyPageStyle = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 145px 0 93px;

  .content-wrapper {
    max-width: 917px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 661px;
      margin-left: -28%;

      .title {
        font-size: 60px;
        line-height: 64px;
        color: #fbca00;
        font-family: RubikMedium;
        padding-bottom: 31px;
      }
      .subtitle {
        margin-top: 32px;
        font-size: 20px;
        line-height: 32px;
        color: #1d2332;
        font-family: RubikMedium;
      }
      .note {
        padding-right: 30px;
      }
      p {
        font-size: 15px;
        line-height: 20px;
        color: #a5a7ad;
        font-family: RubikRegular;

        span {
          color: #1d2332;
          font-size: 15px;
          line-height: 20px;
          font-family: RubikMedium;
        }
      }
      a {
        font-size: 15px;
        line-height: 20px;
        font-family: RubikRegular;
        margin-top: 0px;
        word-break: break-all;
      }
      li {
        font-size: 15px;
        line-height: 20px;
        font-family: RubikRegular;
        color: #a5a7ad;
      }
    }
  }

  @media only screen and (max-width: 1120px) {
    .content-wrapper {
      .content {
        margin-left: 0%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content-wrapper {
      justify-content: flex-start;
      align-items: flex-start;

      .content {
        width: 100%;
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 521px;

        .title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .note {
          padding-right: 0px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .content-wrapper {
      .content {
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;

        .title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .note {
          padding-right: 0px;
        }
      }
    }
  }
`;

export { CookiePolicyPageStyle };


